.selectContainer {
  position: relative;
  margin-top: 46px;
  width: 323px;
  font-size: 17px;

  .selectErr {
    border: 2px solid red;
  }

  select {
    font-size: inherit;
    width: 100%;
    height: 30px;
    background: #201942;
    color: white;
    text-align: center;

    option {
      font-size: inherit;
      height: 50px;
      background: #846414;
    }
  }
}

.title {
  margin-top: 20px;

  * {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
    color: #ffffff;

    @media screen and (max-width: 1201px) {
      font-size: 20px;
    }
  }
}

.text {
  display: flex;
  margin: 10px 0;

  * {
    font-size: 17px;
  }
}

.textTitle {
  margin: 10px 0;

  * {
    font-size: 24px;
  }
}

.textBlack * {
  font-size: 17px;
  color: black;
}

.messageErr * {
  top: 0;
  right: -50px;
  position: absolute;
  color: red;
  font-size: 14px;
}

.languageContainer {
  margin-top: 46px;
  display: flex;
}

.inputContainer {
  position: relative;
  // margin-top: 46px;

  input {
    font-style: normal;
    // margin-top: 20px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    width: 300px;
    height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
    margin-right: 16px;
  }

  textarea {
    font-style: normal;
    margin-top: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    // width: 300px;
    // height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
  }

  .inputErr {
    border: 3px solid rgb(190, 67, 221);
  }
}

.select {
  * {
    color: white !important;
    font-size: 13px !important;
  }

  background: #201942;
}

.imageContainer {
  margin-top: 46px;

  .btnContainer {
    display: flex;
    justify-content: space-between;
    width: 500px;
  }

  .imgContainer {
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 12px;
  }

  .imgTitle * {
    display: flex;
    padding: 10px 8px;
    text-align: center;
    width: 190px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: rgba(123, 97, 255, 0.4);
    border-radius: 12px;

    &.hog {
      background: rgb(132, 100, 20);
    }
  }

  .imgTitleHOG * {
    display: flex;
    padding: 10px 8px;
    text-align: center;
    width: 190px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    background: #846414;
    border-radius: 12px;
  }
}

.flexContainer {
  display: flex;
}

.choiceContainer {
  .titleInput {
    margin-top: 15px;
  }

  .columnContainer {
    margin-top: 20px;

    .titleInput div {
      background: rgba(22, 17, 48, 0.2);
      border-radius: 10px;
      padding: 10px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        background: rgba(123, 97, 255, 0.4);
      }

      span {
        font-size: 25px;
      }
    }

    .active {
      background: rgba(123, 97, 255, 0.682);
      border-radius: 10px;

      &.hog {
        background: rgb(132, 100, 20);
      }
    }
  }
}

.titleContent {
  margin-top: 25px;
  display: flex;
}

.textarea {
  margin-top: 20px;
  width: 400px;
  height: 184px;
  background: #201942;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: white;
  padding: 12px 16px;
}

.secondTextContainer {
  margin-left: 25px;
}

.textareaErr {
  position: relative;
  border: 3px solid rgb(190, 67, 221);
}

.titleInput,
.titleInput * {
  width: 400px;
  margin-bottom: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
}

.colorMenuContainer {
  width: 300px;
  height: 50px;
  border-radius: 10px;
  background: #201942;
  border: 1px solid rgba(255, 255, 255, 0.472);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.colorContainer {
  display: flex;
  justify-content: space-between;
  width: 250px;

  .colorItem {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid white;
  }

  .active {
    box-shadow: 0px 0px 5px black;
    border: 2px double black;
  }

  .darkRed {
    background: rgb(192, 21, 17);
  }

  .red {
    background: rgb(254, 0, 23);
  }

  .orange {
    background: rgb(255, 193, 19);
  }

  .yellow {
    background: rgb(254, 255, 17);
  }

  .green {
    background: rgb(145, 209, 79);
  }

  .darkGreen {
    background: rgb(23, 176, 80);
  }

  .lightBlue {
    background: rgb(22, 176, 240);
  }

  .blue {
    background: rgb(10, 111, 192);
  }

  .darkBlue {
    background: rgb(6, 32, 96);
  }

  .lilac {
    background: rgb(112, 47, 161);
  }
}

.errMess {
  position: absolute;
  color: red;
  top: 14px;
  padding: 30px 20px;

  span {
    color: rgb(235, 134, 255);

    font-size: 14px;
  }
}

.submitErr {
  background: grey;

  &:hover {
    background: grey;
  }
}

.addBtnContainer {
  margin-top: 10px;
  width: 100%;
}

.addBtn {
  width: 200px;
  height: 30px;
  position: relative;
  background: #2d235d;
  border-radius: 12px;
  border: 1px solid grey;
  cursor: pointer;

  * {
    font-size: 15px;
    line-height: 30px;
  }
}

.btnContainer {
  position: relative;
  margin-top: 10px;
}

.delBtn {
  position: absolute;
  top: 5px;
  left: 10px;
  background: url("../../../img/MainCreateBlock/cross.png") center no-repeat,
    #2d235d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  z-index: 2;
  cursor: pointer;
}

.tooltipContainer {
  display: flex;
  span {
    font-size: 17px;
  }
}

.tooltip {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: 20px;
  background: #f3ab22;
  align-items: center;
  width: 25px;
  height: 25px;
  border: 2px dotted rgb(0, 34, 61);
  font-family: "Roboto", sans-serif;
  border-radius: 50%;
  font-weight: bold;
  color: #201942;
  cursor: pointer;
}
