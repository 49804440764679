.hiddenMenu{
   animation: hiddenMenu .25s linear;
   .itemContainer .btnContainerHidden{
      animation: hiddenContainer .25s linear;
      display: none;
   }
}
.showMenu{
   animation: showMenu .25s linear;
   height: 210px;
   .itemContainer .btnContainerHidden{
      animation: showContainer .25s linear;
      display: flex;
   }
}
.itemContainer{
   margin-top: 20px;
   overflow: hidden;
   width: 100%;
   padding: 20px 32px 24px 32px;
   /////////////////////////////
   border-radius: 30px;
   background: #201942;
   @media screen and (max-width: 1201px){
      padding: 15px 20px;
   }
   .info{
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   .title{
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.05em;
      color: #FFFFFF;
      @media screen and (max-width: 1201px){
         font-size: 20px;
      }
   }
   .status{
      display: flex;
      justify-content: space-between;
      width: 150px;
      font-size: 24px;
      &__pro{
         color: red;
      }
      &__live{
         color: white;
      }
   }
   .infoContainer{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .date{
         margin-top: 10px;
         font-style: normal;
         font-weight: 400;
         font-size: 15px;
         line-height: 18px;
         text-align: right;
         letter-spacing: 0.05em;
         color: rgba(255, 255, 255, 0.56);
      }
   .statusContainer{
      display: flex;
      align-items: center;
      .indicator{
         margin-left: 6px;
         width: 6px;
         height: 6px;
         border-radius: 50%;
         margin-right: 8px;
      }
      .grean{
         background: green;
      }
      .red{
         background: red;
      }
      .status{
         font-style: normal;
         font-weight: 400;
         font-size: 17px;
         line-height: 20px;
         letter-spacing: 0.05em;
         color: rgba(255, 255, 255, 0.4);
      }
      .statusResult{
         margin-right: 8px;
         font-style: normal;
         font-weight: 400;
         font-size: 17px;
         line-height: 20px;
         display: flex;
         align-items: flex-end;
         letter-spacing: 1px;
         color: #FFFFFF;
      }
   }
   }

}


.btnContainer{
   display: flex;
   align-items: center;
   margin-top: 20px;
   .edit{
      position: relative;
      flex: 1 1 326px;
      height: 44px;
      left: 0px;
      top: 0px;
      background: rgba(123, 97, 255, 0.4);
      border-radius: 12px;
      padding-left: 10px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      *{
         font-style: normal;
         font-weight: 400;
         font-size: 17px;
         line-height: 20px;
         color: #FFFFFF;
      }
      @media screen and (max-width: 1201px){
         font-size: 15px;
         height: 35px;
      }
      & img{
         position: absolute;
         top: 12px;
         left: 30px;
         width: 19.97px;
         height: 20px;
         @media screen and (max-width: 1790px){
            left: 14px;
         }
         @media screen and (max-width: 1201px){
            top: 9px;
         }/*
         @media screen and (max-width: 1242px){
            left: 17px;
         }
         @media screen and (max-width: 1201px){
            top: 8px;
            left: 15px;
         } */
         @media screen and (max-width: 860px){
            left: 10px;
         }
      }
   }
   .create{
      position: relative;
      margin-left: 10px;
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: #FFFFFF;
      height: 44px;
      background: rgba(123, 97, 255, 0.4);
      border-radius: 12px;
      padding-left: 11px;
      flex: 1 1 auto;
      @media screen and (max-width: 1201px){
         font-size: 15px;
         height: 35px;
      }
      & img{
         position: absolute;
         top: 13px;
         left: 65px;
         width: 19.97px;
         height: 20px;
         @media screen and (max-width: 1790px){
            left: 14px;
         }/*
         @media screen and (max-width: 1540px){
            left: 18px;
         } */
         @media screen and (max-width: 1201px){
            left: 12px;
            top: 8px;
         }
      }
   }
   ////////////////////////////////////////////////////////////////////
   .switch{
      position: relative;
      margin-left: 10px;
      flex: 0 0 44px;
      height: 44px;
      width: 44px;
      @media screen and (max-width: 1201px){
         height: 35px;
         width: 35px;
      }
      .switchOff{
         transform: rotate(180deg);
         position: absolute;
         top: 20px;
         left: 19px;
         width: 8px;
         height: 4px;
         background: url(../../../img/MainBlock/arrow.png) no-repeat;
         background-size: contain;
         @media screen and (max-width: 1540px){
            left: 18px;
         }
         @media screen and (max-width: 1201px){
            top: 16px;
            left: 17px;
         }
      }
      .switchOn{
         transform: rotate(180deg);
         position: absolute;
         top: 20px;
         left: 19px;
         width: 8px;
         height: 4px;
         background: url(../../../img/MainBlock/arrowOff.png) no-repeat;
         background-size: contain;
         @media screen and (max-width: 1540px){
            left: 18px;
         }
         @media screen and (max-width: 1201px){
            top: 16px;
            left: 17px;
         }
      }
   }
   ///////////////////////////////////////
}
.btnContainerHidden{
   width: 100%;
   height: 100%;
   margin-top: 10px;
   display: flex;
   .statistics{
      position: relative;
      height: 44px;
      background: rgba(123, 97, 255, 0.4);
      border-radius: 12px;
      display: block;
      margin-left: 10px;
      padding-left: 11px;
      flex: 1 1 auto;
      *{
         font-style: normal;
         font-weight: 400;
         font-size: 17px;
         line-height: 20px;
         color: #FFFFFF;
      }
      i {
         right: 11px;
      }
      &:first-child{
         margin-left: 0;
      }
      @media screen and (max-width: 1201px){
         height: 35px;
         font-size: 15px;
      }
      & img{
         position: absolute;
         top: 13px;
         left: 8%;
         width: 18px;
         height: 18px;
         object-fit: contain;

         @media screen and (max-width: 1790px){
            left: 8px;
         }
         @media screen and (max-width: 1201px){
            top: 7px;
            left: 30px;
         }
      }
   }
}
}

@keyframes hiddenMenu {
   0%{height: 210px;}
   25%{height: 196px;}
   50%{height: 183px;}
   75%{height: 170px;}
   100%{height: 156px;}
}
@keyframes showMenu {
   0%{height: 156px;}
   25%{height: 170px;}
   50%{height: 183px;}
   75%{height: 196px;}
   100%{height: 210px;}
}

@keyframes hiddenContainer {
   0%{opacity: 1;}
   25%{opacity: .75;}
   50%{opacity: .5;}
   75%{opacity: .25;}
   100%{opacity: 0;}
}
@keyframes showContainer {
   0%{opacity: 0;}
   25%{opacity: .25;}
   50%{opacity: .5;}
   75%{opacity: .75;}
   100%{opacity: 1;}
}
