.dialog {
  background-color: #3f317c;
  color: white;
  padding: 50px 20px;
  overflow: auto;
  border-radius: 10px;
  max-height: 600px;
  &.hog {
    background: rgb(0 0 0);
    border: 1px solid rgb(132, 100, 20);
    border-radius: 10px;
  }
}
.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
}
.boxDialogContent {
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 26px;
  padding: 0 20px 20px;
}
.buttonWrapper {
  margin-top: 36px;
  text-align: center;
  margin-bottom: 20px;
}
.sortFilterContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1112px) {
    margin-top: 20px;
    width: 100%;
    justify-content: space-around;
  }
}

.inputContainer {
  position: relative;

  .titleInput,
  .titleInput * {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    width: 100%;
    height: 40px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 10px;
  }

  .inputErr {
    border: 1px solid red;
  }
}

.descriptionTextarea {
  width: 100%;
  height: 184px;
  background: #201942;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.56);
  padding: 12px 16px;

  &.error {
    border: 1px solid red;
  }
}

.messageErr {
  // top: 0;
  // right: 0;
  // position: absolute;
  color: red;
  font-style: 12px;
}

.btnBan {
  color: white;
  font-size: 17px;
  width: 70px;
  background: grey !important;
  // cursor: not-allowed;
}

.btnBanned {
  color: white;
  font-size: 17px;
  width: 80px;
  background: rgba(246, 71, 71);
}

.btnBanned:hover {
  background: rgba(246, 71, 71);
}

.descriptionTitle * {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.98);
}
.paginatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  button {
    color: white;
  }
}
.user {
  color: #b67e2a;
}
.userGames {
  display: flex;
  flex-direction: column;
}
.userGamesContainer {
  border: 2px solid rgb(97, 96, 104);
  padding: 4px;
  margin: 5px;
}
.gameTitle {
  color: rgb(191, 171, 251);
}
.gameName {
  text-align: start;
  margin-left: 5px;
}
.nickTitle {
  color: #b67e2a;
}
.nickname {
  text-align: start;
  margin-left: 5px;
}
.gameContainer {
  display: flex;
  flex-wrap: wrap;
}
.nickContainer {
  display: flex;
  flex-wrap: wrap;
}
