.eventsHeaderMenuContainer {
   display: flex;
   margin-top: 34px;
   margin-bottom: 12px;

   @media screen and (max-width: 1005px) {
      margin-top: 15px;
   }

   .item {
      position: relative;
      padding: 0 20px;
      cursor: pointer;

      * {
         font-style: normal;
         font-weight: 400;
         font-size: 20px;
         line-height: 24px;
         text-align: center;
         color: rgba(255, 255, 255, 0.4);
      }
   }

   .active {
      padding-bottom: 4px;
      border-bottom: 3px solid #FFFFFF;

      * {
         color: #FFFFFF;
      }
   }

   .activeHOG {
      padding-bottom: 4px;
      border-bottom: 3px solid #846414;

      * {
         color: #846414;
      }
   }
}