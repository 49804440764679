.linksRow {
    display: flex;
    justify-content: space-around;
    margin-bottom: 58px;
}

.dashboardLink {
    display: block;
    width: 500px;
    background: #201942;
    padding: 20px 32px 24px 32px;
    border-radius: 30px;
    cursor: pointer;
}

.hogasia  .dashboardLink {
    background: rgba(215, 188, 51, 0.5);
}

.linkTitle {
    margin-bottom: 15px;
    margin-right: 10px;
    font-size: 22px;
    color: '#fff'
}

.text {
    color: '#fff'
}

.iconLock {
    position: absolute;
    top: 12px;
    right: 0px;
    display: block;
    width: 15px;
    height: 21px;
    background: url("../../img/MainBlock/lock.svg") no-repeat;
}
