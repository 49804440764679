.mainBlock {
  min-height: 100vh;
  min-width: 1000px;
  display: flex;
  flex-direction: column;
  // align-items: center;

  .contentHeaderTitleContainer {
    width: 100%;
    height: 15%;

    @media screen and (max-height: 1556px) {
      height: 16%;
    }

    @media screen and (max-height: 1366px) {
      height: 18%;
    }

    @media screen and (max-height: 1230px) {
      height: 20%;
    }

    @media screen and (max-height: 1096px) {
      height: 22%;
    }

    @media screen and (max-height: 1070px) {
      height: 16%;
    }

    @media screen and (max-width: 1005px) {
      height: 20%;
    }

    .eventsHeaderTitleContainer {
      margin-top: 32px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: 1005px) {
        margin-top: 16px;
      }

      .title * {
        font-style: normal;
        font-weight: 500;
        font-size: 36px;
        line-height: 43px;

        @media screen and (max-width: 1005px) {
          font-size: 25px;
        }
      }

      .buttonContainer {
        display: flex;
        align-items: center;

        .exportBtn {
          width: 101px;
          height: 48px;
          color: rgba(255, 255, 255, 0.56);
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.05em;
          background: rgba(123, 97, 255, 0.2);
          border-radius: 18px;

          @media screen and (max-width: 1005px) {
            font-size: 15px;
            height: 28px;
            width: 89px;
          }
        }

        .addEventBtn {
          position: relative;
          text-align: right;
          padding: 11px 18px 11px 46px;
          margin-left: 46px;
          height: 48px;
          background: rgba(123, 97, 255, 0.4);
          border-radius: 18px;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.05em;
          color: #ffffff;
          display: inline-block;

          @media screen and (max-width: 1242px) {
            font-size: 0;
            padding-left: 35px;
          }

          @media screen and (max-width: 1005px) {
            padding: 2px 18px 5px 38px;
            font-size: 15px;
            height: 28px;
            display: inline-block;
          }
        }
      }
    }

    .mainSattingsContainer {
      width: 100%;
      margin-top: 32px;

      @media screen and (max-width: 1355px) {
        display: block;
        justify-content: center;
      }

      @media screen and (max-width: 1112px) {
        flex-direction: column;
        align-items: center;
      }

      @media screen and (max-width: 1005px) {
        margin-top: 12px;
      }

      .settingsContainer {
        width: 450px;

        @media screen and (max-width: 1355px) {
          margin: 0;
        }

        .btnContainer {
          margin-top: 46px;
          // display: flex;
          // justify-content: space-between;
          // width: 100%;
          background: #201942;
          border-radius: 20px;

          .btnInnerContainer {
            position: relative;
            // width: 170px;
            margin: 20px 20px;
            .calendarModal {
              padding: 20px;
              border: 1px solid rgb(202, 199, 199);
              background: #201942;
              position: absolute;
              border-radius: 12px;
              width: 300px;
              display: flex;
              justify-content: center;
              z-index: 10;
            }

            .titleBtn * {
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 20px;
              margin-bottom: 10px;
              display: block;
              text-transform: capitalize;
            }

            .filterBtn {
              position: relative;
              margin-top: 10px;
              width: 100%;
              height: 48px;
              border-radius: 12px;
              overflow: hidden;
              width: 100%;
              text-align: right;
              padding-right: 16px;
              background: rgba(123, 97, 255, 0.4);
              color: #ffffff;
              font-style: normal;
              font-weight: 400;
              font-size: 17px;
              line-height: 20px;
              letter-spacing: 0.05em;

              &::before {
                content: "";
                position: absolute;
                top: 15px;
                left: 17px;
                width: 18px;
                height: 18px;
                background: url("../../../img/MainCreateBlock/buttonPeriod.png")
                  no-repeat;
              }
            }
          }
        }
      }

      .imageContainer {
        width: 250px;
        margin-top: 46px;

        @media screen and (max-width: 1355px) {
          justify-content: center;
          margin: 10px auto 0 0;
        }

        .imgContainer {
          width: 100%;
          height: 158px;
          border-radius: 12px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .uploadTitle {
          margin-top: 10px;
          background: rgba(123, 97, 255, 0.4);
          border-radius: 12px;
          padding: 10px;
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: center;

          * {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            line-height: 25px;
            letter-spacing: 0.05em;
            color: #ffffff;
          }
        }
      }
    }

    .descriptionContainer {
      margin-top: 46px;
      position: relative;
      // width: 56%;

      @media screen and (max-width: 1552px) {
        width: 100%;
      }

      .textareaErr {
        textarea {
          border: 1px solid red;
        }
      }

      .descriptionTitle * {
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 20px;
        text-transform: capitalize;
        color: rgba(255, 255, 255, 0.98);
      }

      .descriptionTextarea {
        margin-top: 12px;
        // width: 100%;
        height: 184px;
        background: #201942;
        border: 1px solid rgba(255, 255, 255, 0.12);
        box-sizing: border-box;
        border-radius: 12px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.56);
        padding: 12px 16px;
      }
    }

    .organizerPartnerFromContainr {
      margin-top: 32px;
      width: 100%;
    }

    .createBtn {
      position: relative;
      margin: 66px 0;
      background: rgba(123, 97, 255, 0.2);
      border-radius: 12px;
      width: 250px;
      height: 52px;
      text-align: right;
      padding: 16px;
      cursor: pointer;

      &:disabled,
      &[disabled] {
        background: grey;
      }

      &::before {
        content: "";
        position: absolute;
        top: 17px;
        left: 17px;
        background: url("../../../img/MainCreateBlock/cross.png") no-repeat;
        width: 18px;
        height: 18px;
      }

      * {
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #ffffff;
      }
    }
  }
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
  text-align: center;
}
.inputContainer {
  position: relative;

  .titleInput,
  .titleInput * {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    width: 100%;
    height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
  }

  .inputErr {
    border: 1px solid red;
  }
}

.messageErr {
  // top: 0;
  // right: 0;
  // position: absolute;
  color: red;
  font-style: 12px;
}

.selectContainer {
  position: relative;
  margin-top: 46px;
  width: 323px;
  font-size: 14px;
  margin-bottom: 10px;

  .selectErr {
    border: 2px solid red;
  }

  select {
    font-size: 14px;
    width: 100%;
    height: 30px;
    background: #201942;
    color: white;
    text-align: center;

    option {
      font-size: inherit;
      height: 50px;
    }
  }
}

.addBtnContainer {
  margin-top: 10px;
  width: 100%;
  // display: flex;
  // justify-content: center;
}

.addImg {
  width: 70px;
  height: 70px;
  position: relative;
  background: #2d235d;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid grey;

  &:before {
    content: "";
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% - 9px);
    background: url("../../../img/MainCreateBlock/cross.png") no-repeat;
    width: 18px;
    height: 18px;
  }
}

.inputContainer {
  margin-top: 46px;
  width: 323px;

  @media screen and (max-width: 1376px) {
    width: 100%;
  }

  .title * {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-transform: capitalize;
  }

  .inputEvent {
    margin-top: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
    width: 100%;
    height: 48px;
  }
}

.btnContainer {
  margin-top: 20px;
  // width: 100%;
}

.text * {
  margin-bottom: 10px;
  font-size: 17px;
}

.inputText * {
  margin-bottom: -20px;
  padding-top: 20px;
  font-size: 22px;
}

button {
  cursor: pointer;
  max-width: 320px;

  &:hover {
    background: rgba(123, 97, 255, 0.4);
  }
}

.inputContainerLower {
  margin-top: 46px;
  max-width: 650px;
}

.inputBr {
  margin-top: 20px;
}

.socialNetworksContainer {
  margin-top: 50px;
  max-width: 500px;
}

.inputBlock {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media screen and (max-width: 1376px) {
    flex-direction: column;
    align-items: center;
  }
}

.title * {
  font-size: 17px;
  height: 50px;
  line-height: 50px;
}

.inputMainBottomContainer {
  margin-top: 32px;

  &:first-child {
    margin: 0;
  }

  @media screen and (max-width: 1376px) {
    display: block;
  }

  .imageContainer {
    margin-top: 46px;

    .btnContainer {
      display: flex;
      justify-content: space-between;
      width: 500px;
    }

    .imgContainer {
      width: 80px;
      height: 80px;
      overflow: hidden;
      border-radius: 12px;
    }

    .imgTitle * {
      padding: 10px 8px;
      text-align: center;
      width: 170px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.05em;
      color: #ffffff;
      background: rgba(123, 97, 255, 0.4);
      border-radius: 12px;
    }
  }
}

.addBtnContainer {
  margin-top: 10px;
  width: 100%;
}

.addBtn {
  // margin-left: -220px;
  width: 200px;
  height: 30px;
  // position: relative;
  background: #2d235d;
  border-radius: 12px;
  border: 1px solid grey;
  cursor: pointer;

  * {
    font-size: 15px;
    line-height: 30px;
  }
}

.btnContainer {
  position: relative;
  margin-top: 20px;
}

.delBtn {
  position: absolute;
  top: 4px;
  left: 10px;
  background: url("../../../img/MainCreateBlock/cross.png") center no-repeat,
    #2d235d;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  z-index: 2;
  cursor: pointer;
}

.submitErr {
  background: grey;

  &:hover {
    background: grey;
    cursor: none;
  }
}

.select {
  * {
    color: white !important;
    font-size: 13px !important;
  }

  background: #201942;
}

.errorLabel {
  color: red;
  margin-top: 8px;
  display: block;
}

.tabsWrapper {
  display: flex;
  margin-top: 20px;
  top: 0em;
  background: #6c33ad;
  height: 50px;
  max-width: 600px;
  position: sticky;
  z-index: 11;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  padding: 10px;
  .tab {
    background: #201942;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
}
.showDesctiptionBlockHidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 200ms ease, opacity 200ms ease;
}
.showDesctiptionBlockActive {
  max-height: 100%;
  opacity: 1;
  transition: max-height 600ms ease, opacity 200ms ease;
}
.showSettingsBlockHidden {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 200ms ease, opacity 200ms ease;
}
.showSettingsBlockActive {
  max-height: 100%;
  opacity: 1;
  transition: max-height 600ms ease, opacity 200ms ease;
}
textarea {
  min-width: 500px;
}
