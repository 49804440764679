.sortFilterContainer {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1112px) {
    margin-top: 20px;
    width: 100%;
    justify-content: space-around;
  }

  .sortContainer {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.56);
    margin-right: 52px;
    cursor: pointer;

    @media screen and (max-width: 1242px) {
      font-size: 0;
    }

    @media screen and (max-width: 1112px) {
      font-size: 17px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      right: -28px;
      width: 20px;
      height: 16px;
      background: url(../../img/MainBlock/arrows.png) no-repeat;
      background-size: contain;
    }
  }

  .filterContainer {
    position: relative;
    margin-right: 52px;
    cursor: pointer;

    * {
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      letter-spacing: 0.05em;
      color: rgba(255, 255, 255, 0.56);
    }

    @media screen and (max-width: 1242px) {
      font-size: 0;
    }

    @media screen and (max-width: 1112px) {
      font-size: 17px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      right: -28px;
      width: 20px;
      height: 16px;
      background: url(../../img/MainBlock/filter.png) no-repeat;
      background-size: contain;
    }
  }

  .inputContainer {
    position: relative;
    width: 191px;
    height: 40px;

    input {
      border: 1.5px solid rgba(255, 255, 255, 0.4);
      width: 100%;
      height: 100%;
      border-radius: 12px;
      background: transparent;
      color: rgba(255, 255, 255, 0.4);
      padding: 9.5px 56px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 10.5px;
      left: 14.5px;
      left: 24px;
      width: 18.5px;
      height: 18.5px;
      background: url(../../img/MainBlock/Search.png) no-repeat;
      background-size: contain;
    }
  }
}

.inputContainer {
  position: relative;

  .titleInput,
  .titleInput * {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255, 255, 255);
    width: 100%;
    height: 40px;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px 10px;
  }

  .inputErr {
    border: 1px solid red;
  }
}

.descriptionTextarea {
  width: 100%;
  height: 184px;
  background: #201942;
  border: 1px solid rgba(255, 255, 255, 0.12);
  box-sizing: border-box;
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.56);
  padding: 12px 16px;

  &.error {
    border: 1px solid red;
  }
}

.messageErr {
  // top: 0;
  // right: 0;
  // position: absolute;
  color: red;
  font-style: 12px;
}

.btnBan {
  color: white;
  font-size: 17px;
  width: 70px;
  background: grey !important;
  // cursor: not-allowed;
}

.btnBanned {
  color: white;
  font-size: 17px;
  width: 80px;
  background: rgba(246, 71, 71);
}

.btnBanned:hover {
  background: rgba(246, 71, 71);
}

.descriptionTitle * {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  text-transform: capitalize;
  color: rgba(255, 255, 255, 0.98);
}
.paginatorWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  button {
    color: white;
  }
}
