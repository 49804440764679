.landingContainer{
   margin-bottom: 20px;
   width: 100%;
   height: 100%;
   .rowContainer{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .baku{
         width: 62px;
         height: 80px;
      }
      .esports{
         width: 96px;
         height: 80px;
      }
      .azerbaijan{
         width: 126px;
         height: 80px;
      }
      .pass{
         width: 126px;
         height: 80px;
      }
      img{
         width: 100%;
         height: 100%;
      }
   }
}