.supportContainer{
   margin-top: 20px;
   width: 100%;
   display: flex;
   justify-content: center;
   font-size: 16px;
   letter-spacing: 1.5px;
   .contentContainer{
      width: 320px;
      .titleInput *{
         font-size: 14px;
      }
      .titleSupport *{
         font-size: 25px;
         text-align: center;
      }
      .inputContainert{
         position: relative;
         margin-top: 24px;
         input, textarea{
            margin-top: 5px;
            width: 100%;
            height: 35px;
            color: #000;
            font-size: 20px;
            padding: 0 10px;font-weight: bold;
         }
         textarea{
            height: 400px;
            width: 100%;
            padding: 10px 10px;
            resize: none
         }
      }
      button{
         margin-top: 35px;
         width: 100%;
         height: 35px;
         border-radius: 8px;  
      }
      .btnRegister{
         background: #f2702c;
         color: white;
         cursor: pointer;
      }
      .disabledBTN{
         background: grey;
         cursor: no-drop;
      }
   }
   
}
.errInput{
   border: 2px solid red;
}
.errText{
   position: absolute;
   bottom: -17px;
   color: red;
}