.modal__wrapper {
  padding: 15px 15px 60px 15px;
  box-sizing: border-box;
}
.modal {
  width: 500px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
  background: rgb(46, 25, 65);
  background: linear-gradient(
    90deg,
    rgba(46, 25, 65, 1) 0%,
    rgba(53, 49, 124, 1) 52%
  );
  color: #ffffff;
}
.modalHOG {
  width: 500px;
  padding: 20px 12px;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 20px;
  border: 2px #707070 solid;
  background: rgb(38, 34, 35);
  background: linear-gradient(
    110deg,
    rgba(38, 34, 35, 1) 0%,
    rgba(191, 140, 44, 1) 100%
  );
  color: #ffffff;
}

.modal__image {
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
  width: 100px;
}

.modal__title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.modal__title__header {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
