.contentHeaderTitleContainer {
   z-index: 0;
   width: 100%;
   height: 15%;

   @media screen and (max-height: 1556px) {
      height: 16%;
   }

   @media screen and (max-height: 1366px) {
      height: 18%;
   }

   @media screen and (max-height: 1230px) {
      height: 20%;
   }

   @media screen and (max-height: 1096px) {
      height: 22%;
   }

   @media screen and (max-height: 1070px) {
      height: 16%;
   }

   @media screen and (max-width: 1005px) {
      height: 20%
   }

   .eventsHeaderTitleContainer {
      margin-top: 32px;
      margin-bottom: 24px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1005px) {
         margin-top: 16px;
      }

      .title * {
         font-style: normal;
         font-weight: 500;
         font-size: 36px;
         line-height: 43px;

         @media screen and (max-width: 1005px) {
            font-size: 25px;
         }
      }

      .buttonContainer {
         display: flex;
         align-items: center;

         .exportBtn {
            position: relative;
            width: 101px;
            height: 48px;
            background: rgba(123, 97, 255, 0.2);
            border-radius: 18px;

            * {
               color: rgba(255, 255, 255, 0.56);
               font-style: normal;
               font-weight: 400;
               font-size: 20px;
               line-height: 24px;
               letter-spacing: 0.05em;
            }

            @media screen and (max-width: 1005px) {
               font-size: 15px;
               height: 28px;
               width: 89px;
            }
         }

         .addEventBtn {
            position: relative;
            text-align: right;
            padding: 11px 18px 11px 46px;
            margin-left: 46px;
            height: 48px;
            background: rgba(123, 97, 255, 0.4);
            border-radius: 18px;
            display: inline-block;

            * {
               font-style: normal;
               font-weight: 400;
               font-size: 20px;
               line-height: 24px;
               letter-spacing: 0.05em;
               color: #FFFFFF;
            }

            @media screen and (max-width: 1242px) {
               font-size: 0;
               padding-left: 35px;
            }

            @media screen and (max-width: 1005px) {
               padding: 2px 18px 5px 38px;
               font-size: 15px;
               height: 28px;
               display: inline-block;
            }

            /* @media screen and (max-width: 1242px){
            font-size: 15px;
            height: 28px;
            display: inline-block;
         } */
            &::after {
               content: '';
               position: absolute;
               top: 15px;
               left: 17px;
               width: 18px;
               height: 18px;
               background: url(../../../img/MainBlock/buttonCross.png) no-repeat;
               background-size: contain;

               @media screen and (max-width: 1005px) {
                  top: 5px;
                  left: 15px;
               }
            }
         }
      }
   }

   .eventsHeaderFilterContainer {
      // margin-top: 32px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 1112px) {
         flex-direction: column;
         align-items: center;
      }

      @media screen and (max-width: 1005px) {
         margin-top: 12px;
      }

      .periodContainer {
         width: 50%;
         display: flex;
         align-items: center;

         @media screen and (max-width: 1112px) {
            width: 80%;
            justify-content: space-around;
         }

         @media screen and (max-width: 1005px) {
            width: 70%;
         }

         @media screen and (max-width: 992px) {
            width: 50%;
         }

         @media screen and (max-width: 852px) {
            width: 70%;
         }

         .periodTitle * {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;

            @media screen and (max-width: 1005px) {
               font-size: 19px;
            }
         }

         .fromBtn {
            position: relative;
            margin-left: 21px;
            width: 100px;
            height: 40px;
            background: rgba(123, 97, 255, 0.4);
            border-radius: 12px;
            padding-right: 12px;

            * {
               font-style: normal;
               font-weight: 400;
               font-size: 17px;
               line-height: 20px;
               letter-spacing: 0.05em;
               color: #FFFFFF;
               text-align: right;
               margin-left: 30px;

               @media screen and (max-width: 1315px) {
                  font-size: 0;
               }

               @media screen and (max-width: 1112px) {
                  font-size: 17px;
               }

               @media screen and (max-width: 1005px) {
                  font-size: 15px;
               }
            }

            @media screen and (max-width: 1315px) {
               width: 45px;
            }

            @media screen and (max-width: 1112px) {
               width: 100px;
            }

            @media screen and (max-width: 1005px) {
               width: 90px;
               height: 30px;
            }

            &::after {
               content: '';
               position: absolute;
               top: 11px;
               left: 13px;
               width: 18px;
               height: 18px;
               background: url(../../../img/MainBlock/buttonPeriod.png) no-repeat;
               background-size: contain;

               @media screen and (max-width: 1005px) {
                  top: 6px;
                  left: 11px;
               }
            }
         }

         .line {
            margin-left: 14px;
            width: 12px;
            height: 4px;
            background: rgba(255, 255, 255, 0.56);
            border-radius: 12px;
         }

         .toBtn {
            position: relative;
            margin-left: 14px;
            width: 75px;
            height: 40px;
            background: rgba(123, 97, 255, 0.4);
            border-radius: 12px;

            * {
               font-style: normal;
               font-weight: 400;
               font-size: 17px;
               line-height: 20px;
               letter-spacing: 0.05em;
               color: #FFFFFF;
               text-align: right;
               margin-left: 18px;

               @media screen and (max-width: 1315px) {
                  font-size: 0;
               }

               @media screen and (max-width: 1112px) {
                  font-size: 17px;
               }

               @media screen and (max-width: 1005px) {
                  font-size: 15px;
               }
            }

            @media screen and (max-width: 1315px) {
               width: 45px;
            }

            @media screen and (max-width: 1112px) {
               width: 75px;
            }

            @media screen and (max-width: 1005px) {
               width: 65px;
               height: 30px;
            }

            &::after {
               content: '';
               position: absolute;
               top: 11px;
               left: 13px;
               width: 18px;
               height: 18px;
               background: url(../../../img/MainBlock/buttonPeriod.png) no-repeat;
               background-size: contain;

               @media screen and (max-width: 1005px) {
                  top: 6px;
                  left: 11px;
               }
            }
         }

         .panelBtn {
            position: relative;
            margin-left: 15px;
            width: 170px;
            height: 44px;
            border-radius: 12px;
            background: rgba(123, 97, 255, 0.2);
            font-weight: 500;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: 0.05em;
            color: rgba(255, 255, 255, 0.52);

            img {
               position: absolute;
               left: 10px;
            }
         }

         .panelBtn:first-child {
            margin-left: 0;
         }

         .activeBtn {
            background: #4F4096;
            color: rgba(255, 255, 255, 0.9);
         }

         // .activeBtnHOG {
         //    background: #4F4096;
         //    color: rgba(255, 255, 255, 0.9);
         // }
      }

      .sortFilterContainer {
         display: flex;
         align-items: center;

         @media screen and (max-width: 1112px) {
            margin-top: 20px;
            width: 100%;
            justify-content: space-around;
         }

         .sortContainer {
            position: relative;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 20px;
            letter-spacing: 0.05em;
            color: rgba(255, 255, 255, 0.56);
            margin-right: 52px;
            cursor: pointer;

            @media screen and (max-width: 1242px) {
               font-size: 0;
            }

            @media screen and (max-width: 1112px) {
               font-size: 17px;
            }

            &::after {
               content: '';
               position: absolute;
               top: 2px;
               right: -28px;
               width: 20px;
               height: 16px;
               background: url(../../../img/MainBlock/arrows.png) no-repeat;
               background-size: contain
            }
         }

         .filterContainer {
            position: relative;
            margin-right: 52px;
            cursor: pointer;

            * {
               font-style: normal;
               font-weight: 400;
               font-size: 17px;
               line-height: 20px;
               letter-spacing: 0.05em;
               color: rgba(255, 255, 255, 0.56);
            }

            @media screen and (max-width: 1242px) {
               font-size: 0;
            }

            @media screen and (max-width: 1112px) {
               font-size: 17px;
            }

            &::after {
               content: '';
               position: absolute;
               top: 2px;
               right: -28px;
               width: 20px;
               height: 16px;
               background: url(../../../img/MainBlock/filter.png) no-repeat;
               background-size: contain
            }
         }

         .inputContainer {
            position: relative;
            width: 191px;
            height: 40px;

            input {
               border: 1.5px solid rgba(255, 255, 255, 0.4);
               ;
               width: 100%;
               height: 100%;
               border-radius: 12px;
               background: transparent;
               color: rgba(255, 255, 255, 0.4);
               padding: 9.5px 56px;
            }

            &::after {
               content: '';
               position: absolute;
               top: 10.5px;
               left: 14.5px;
               left: 24px;
               width: 18.5px;
               height: 18.5px;
               background: url(../../../img/MainBlock/Search.png) no-repeat;
               background-size: contain
            }
         }
      }
   }
}