.evensPageContainer {
  margin-top: -10px;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: 100vh;
  align-items: center;
}

.row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.center {
  width: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 20px;

  .titleInput {
    flex: 1;
    white-space: nowrap;
    margin-right: 20px;
    margin-bottom: 0;
    display: block;
  }

  .titleInput,
  .titleInput * {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
  }

  input {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    width: 100%;
    height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
  }

  .inputErr {
    border: 1px solid red;
  }
}

.filterContainer {
  margin: 25px 0;
  width: 100%;
  display: flex;
  justify-content: center;

  select {
    width: 200px;
    height: 25px;
    border-radius: 12px;
    background: rgba(123, 97, 255, 0.2);
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.05em;
    background: #4f4096;
    color: rgba(255, 255, 255, 0.9);
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.373);
  }
}

.mainScrollContainer {
  margin: 20px auto;
  width: 100%;
  height: 90%;

  @media screen and (max-height: 1556px) {
    height: 84%;
  }

  @media screen and (max-height: 1366px) {
    height: 82%;
  }

  @media screen and (max-height: 1230px) {
    height: 80%;
  }

  @media screen and (max-height: 1096px) {
    height: 78%;
  }

  @media screen and (max-height: 1070px) {
    height: 84%;
  }

  @media screen and (max-width: 1434px) {
    width: 100%;
  }

  @media screen and (max-width: 1112px) {
    height: 80%;
  }

  @media screen and (max-width: 1005px) {
    height: 85%;
  }

  .scrollContainer {
    width: 100%;
    overflow: auto;
    height: 100%;

    @media screen and (max-width: 1540px) {
      width: 100%;
    }

    .tabsContainer {
      margin-top: 20px;
      z-index: 1;
    }
  }
}

.userLink {
  color: white;
  text-decoration: underline;
}

.btnWrite {
  width: 100px;
  height: 30px;
}

.btnContainer {
  border-top: 20px;

  .paginatorBtn {
    width: 30px;
    height: 20px;
    margin-right: 5px;
    background: rgba(145, 123, 255, 0.2);
  }

  .currentPage {
    background: rgba(145, 123, 255, 0.46);
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(145, 123, 255, 0.2);
  box-shadow: 0px 0px 3px #060606 inset;
}

::-webkit-scrollbar-thumb {
  background: #969696;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #363636;
}
.addNewPersonBlock {
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition: max-height 200ms ease, opacity 200ms ease;
}
.addNewPersonBlockActive {
  max-height: 1000px;
  opacity: 1;
  transition: max-height 600ms ease, opacity 200ms ease;
}
