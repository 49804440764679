.btnPaginatorContainer {
   margin-top: 32px;
   width: 100%;
   display: flex;
   justify-content: flex-start;
}

.paginatorBtn {
   // padding: 5px;
   width: 40px;
   height: 30px;
   margin-right: 8px;
   background: rgba(145, 123, 255, 0.2);
}

.currentPage {
   background: rgba(145, 123, 255, 0.46);
}