.mainBlock {
  width: 350px;
  transition: all 0.3s ease;
  background: #362f53;
  text-align: center;
  border-radius: 15px;
  border: 2px solid #201942;
}
.inputContainer {
  position: relative;
  // margin-top: 46px;
  padding: 20px 10px;
  input {
    font-style: normal;
    // margin-top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    width: 325px;
    height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
    margin-right: 16px;
  }

  textarea {
    font-style: normal;
    margin-top: 15px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.56);
    // width: 300px;
    // height: 48px;
    background: rgba(32, 25, 66, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px 16px;
  }

  .inputErr {
    border: 3px solid rgb(190, 67, 221);
  }
}

.select {
  * {
    color: white !important;
    font-size: 13px !important;
  }
  text-align: start;
  background: #201942;
}
.selectContainer {
  position: relative;
  margin-top: 20px;
  width: 323px;
  font-size: 17px;

  .selectErr {
    border: 2px solid red;
  }

  select {
    font-size: inherit;
    width: 100%;
    height: 30px;
    background: #201942;
    color: white;

    option {
      font-size: inherit;
      height: 50px;
      background: #846414;
    }
  }
}
.createBtn {
  margin-bottom: 20px;
}
