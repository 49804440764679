.mainBlockContainer {
   display: inline-block;
   margin: 0 auto;
   /* width: 1038px; */
   /* width: 1200px; */

   /* width: 66%; */
   width: 80%;
   /* width: 780px; */
   /* height: 100%; */
   /* transform: translateX(-3.5%); */
   transform: translateX(8%);
}

@media screen and (max-width: 1730px) {
   .mainBlockContainer {
      /* width: 60%; */
      /* width: 70%; */
      transform: translateX(13%);
   }
}

@media screen and (max-width: 1548px) {
   .mainBlockContainer {
      /* width: 58%; */
      width: 78%;
   }
}

@media screen and (max-width: 1435px) {
   .mainBlockContainer {
      /* width: 54%; */
      /* width: 60%; */
   }
}

@media screen and (max-width: 1342px) {
   .mainBlockContainer {
      width: 72%;
      transform: translateX(12%);
   }
}

@media screen and (max-width: 1204px) {
   .mainBlockContainer {
      width: 75%;
   }
}

@media screen and (max-width: 1168px) {
   .mainBlockContainer {
      /* width: 57%; */
   }
}

@media screen and (max-width: 1070px) {
   .mainBlockContainer {
      transform: translateX(5%);
      width: 80%;
   }
}

@media screen and (max-width: 890px) {
   .mainBlockContainer {
      /* transform: translateX(-8%); */
      /* width: 60%; */
   }
}

@media screen and (max-width: 707px) {
   .mainBlockContainer {
      transform: translateX(12%);
      width: 75%;
   }
}

/* //////////////////mainCreateBlock//////////////////*/

.mainCreateBlock {
   width: 1000px;
   width: 35%;
   transform: translateX(-35%);
}

@media screen and (max-width: 1675px) {
   .mainCreateBlock {
      width: 45%;
      transform: translateX(-17%);
   }
}

@media screen and (max-width: 1465px) {
   .mainCreateBlock {
      width: 40%;
      transform: translateX(-25%);
   }
}

@media screen and (max-width: 1465px) {
   .mainCreateBlock {

      /* transform: translateX(-24%); */
   }
}

@media screen and (max-width: 1288px) {
   .mainCreateBlock {
      width: 35%;
      transform: translateX(-30%);
   }
}

@media screen and (max-width: 1204px) {
   .mainCreateBlock {
      width: 40%;
      /* transform: translateX(-32%); */
   }
}

@media screen and (max-width: 1180px) {
   .mainCreateBlock {
      width: 35%;
      /* transform: translateX(-38%); */
   }
}

@media screen and (max-width: 1095px) {
   .mainCreateBlock {
      width: 34%;
      /* transform: translateX(-41%); */
   }
}

@media screen and (max-width: 1070px) {
   .mainCreateBlock {
      width: 40%;
      /* transform: translateX(-48%); */
   }
}

@media screen and (max-width: 1026px) {
   .mainCreateBlock {
      width: 36%;
      /* transform: translateX(-55%); */
   }
}

/* @media screen and (max-width: 1026px){
	.mainCreateBlock{
      position: relative;
   }
} */