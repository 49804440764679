.fileUploader {
   /* margin-top: 20px; */
   display: flex;
   justify-content: space-around;
   align-items: center;
   overflow: auto;
   width: 230px;
}

.btnContainer {}

.addImg {
   position: relative;
   background: #2d235d;
   cursor: pointer;
   width: 50px;
   height: 50px;
   border-radius: 12px;
}

.addImg:before {
   content: '';
   position: absolute;
   top: calc(50% - 9px);
   left: calc(50% - 9px);
   background: url('../../img/MainCreateBlock/cross.png') no-repeat;
   width: 18px;
   height: 18px;
}

.imgContainer {
   width: 100px;
   height: 100px;
   border-radius: 10px;
   overflow: hidden;
   border: 1px solid grey;
}

.imgContainerAdd {
   width: 50px;
   height: 50px;
   border-radius: 10px;
   overflow: hidden;
   border: 1px solid grey;
}

img {
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.uploadImg {
   opacity: 0;
   position: absolute;
   z-index: -1;
}