.authContainer{
   .langContainer{
      position: absolute;
      top: 20px;
      right: 0;
   }
   position: relative;
   margin: 50px auto;
   width: 400px;
   padding: 50px 0;
}