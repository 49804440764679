   .profilePanel {
      position: absolute;
      top: 0;
      left: 0;
      min-width: 252px;
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      min-height: 100%;
      padding-bottom: 32px;
      padding-left: 8px;
      background: #201942;
      // margin-right: 20px;

      @media screen and (max-width: 1204px) {
         // width: 250px;
         align-items: center;
         padding-right: 0px;
      }

      @media screen and (max-width: 1070px) {
         width: 100px;
      }

      .topContainer {
         display: flex;
         flex-direction: column;
         align-items: center;

         .communityMainTitle {
            color: #B67E2A;
            max-width: 252px;
            word-break: break-word;
            text-align: center;
            margin: 0 auto 14px;
         }

         .communityTitle {
            margin-top: 16px;

            * {
               font-style: normal;
               font-weight: 500;
               font-size: 17px;
               line-height: 20px;
               letter-spacing: 0.05em;
               color: #B67E2A;
            }

            @media screen and (max-width: 1204px) {
               font-size: 12px;
            }

            @media screen and (max-width: 1070px) {
               display: none;
            }
         }

         .edit {
            margin-top: 17px;

            * {
               font-size: 14px;
            }

            @media screen and (max-width: 1204px) {
               font-size: 10px;
            }

            @media screen and (max-width: 1070px) {
               display: none;
            }
         }

         .imgContainer {
            margin-top: 25px;
            width: 100px;
            height: 100px;

            @media screen and (max-width: 1070px) {
               width: 50px;
               height: 50px;
            }

            img {
               width: 100%;
            }
         }

         .userName {
            margin-top: 17px;
            font-style: normal;
            font-weight: 400;
            font-size: 17px;

            @media screen and (max-width: 1204px) {
               font-size: 12px;
            }

            @media screen and (max-width: 1070px) {
               font-size: 8px;
            }
         }

         .navLinkContainer {
            margin-top: 44px;

            .linkHOG {
               background: transparent;
               position: relative;
               display: flex;
               width: 204px;
               height: 48px;
               padding: 14px 0px 14px 54px;
               border-radius: 12px;
               cursor: pointer;

               * {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 20px;
                  letter-spacing: 0.05em;
                  text-decoration: none;
                  color: #d7bc33;
               }

               @media screen and (max-width: 1204px) {
                  font-size: 12px;
                  width: 155px;
               }

               @media screen and (max-width: 1070px) {
                  font-size: 0;
                  width: 60px;
                  padding: 14px;
               }

               .iconMenu {
                  position: absolute;
                  top: 15px;
                  left: 19px;
                  width: 20px;
                  height: 20px;

                  .imgContainerMenu {
                     width: 20px;
                     height: 19px;

                     img {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .imgDisable {
                     display: block;
                  }

                  .imgActive {
                     display: none;
                  }
               }


            }

            .link {
               position: relative;
               display: flex;
               height: 48px;
               padding: 14px;
               border-radius: 12px;
               background: #201942;
               width: 100%;
               cursor: pointer;

               * {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 17px;
                  line-height: 20px;
                  letter-spacing: 0.05em;
                  color: #9D90C7;
                  text-decoration: none;
               }

               @media screen and (max-width: 1204px) {
                  font-size: 12px;
               }

               @media screen and (max-width: 1070px) {
                  font-size: 0;
                  width: 60px;
                  padding: 14px;
               }

               .iconMenu {
                  margin-right: 10px;
                  width: 20px;
                  height: 20px;

                  .imgContainerMenu {
                     width: 20px;
                     height: 19px;

                     img {
                        width: 100%;
                        height: 100%;
                     }
                  }

                  .imgDisable {
                     display: block;
                     object-fit: contain;
                  }

                  .imgActive {
                     display: none;
                  }
               }
            }

            .activeHOG {
               background: rgba(215, 188, 51, 0.5);
               color: #d7bc33;

               .iconMenu {
                  .imgDisable {
                     display: none;
                  }

                  .imgActive {
                     display: block;
                  }
               }

            }

            .active {
               background: rgba(145, 123, 255, 0.2);
               color: #FFFFFF;

               .iconMenu {
                  .imgDisable {
                     display: none;
                  }

                  .imgActive {
                     display: block;
                  }
               }

            }
         }
      }

      .bottomContainer {
         display: flex;
         flex-direction: column;
         align-items: center;

         .dataContainer {
            font-weight: 400;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.05em;
            margin-top: 34px;
            width: 230px;
            display: flex;
            justify-content: space-around;

            @media screen and (max-width: 1204px) {
               flex-direction: column;
               width: 90%;

               p:last-child {
                  margin-top: 10px;
               }
            }
         }
      }
   }

   .menuIcon {
      @media screen and (max-width: 1070px) {
         display: none;
      }
   }
