      .MuiInputLabel-formControl {
          color: white !important
      }

      .MuiButton-label {
          color: white !important
      }

      .MuiSelect-icon {
          color: white !important
      }

      .MuiInput-underline:before {
          border-bottom: 1px solid white !important;
      }

      .MuiSelect-select.MuiSelect-select {
          color: white !important
      }

      .MuiAutocomplete-input {
          color: white !important
      }

      .MuiAutocomplete-popupIndicator {
          color: white !important
      }

      .MuiAutocomplete-endAdornment {
          top: -40px !important;
          right: -13px !important;
      }